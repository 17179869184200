export const menuData = [
  {
    label: "Нүүр",
    key: "/",
  },
  {
    label: "Баянгол дүүрэг",
    children: [
      {
        type: "group",
        label: "Баянгол дүүрэг",
        children: [
          {
            label: "Дүүргийн танилцуулга",
            key: "/pages/about-us",
          },
          {
            label: "Дүүргийн бэлгэдэл",
            key: "/pages/symbol",
          },
          {
            label: "Дүүргийн сүлд дуу",
            key: "/pages/anthem",
          },
        ],
      },
      {
        type: "group",
        label: "Засаг дарга",
        children: [
          {
            label: "Засаг даргын мэндчилгээ",
            key: "/pages/greetings",
          },
          {
            label: "Засаг даргын үйл ажиллагааны хөтөлбөр",
            key: "/pages/program",
          },
          {
            label: "Эдийн засаг, нийгмийг хөгжүүлэх үндсэн чиглэл",
            key: "/pages/strategy",
          },
        ],
      },
      {
        type: "group",
        label: "Засаг даргын Тамгын газар",
        children: [
          {
            label: "Засаг даргын Тамгын газрын танилцуулга",
            key: "/pages/about-us-department",
          },
          {
            label: "Засаг даргын Тамгын газрын алсын хараа, эрхэм зорилго",
            key: "/pages/vision",
          },
          {
            label: "Удирдлагууд",
            key: "/pages/executives",
          },
        ],
      },
    ],
  },
  {
    label: "Мэдээ, Мэдээлэл",
    children: [
      {
        label: "Цаг үеийн мэдээ",
        key: "/pages/newsfeed",
      },
      // {
      //   label: "Дүрстэй мэдээ",
      //   key: "/pages/videoNews",
      // },
      // {
      //   label: "Фото мэдээ",
      //   key: "/pages/photoNews",
      // },
      {
        label: "Шуурхай зөвлөгөөн",
        key: "/pages/shuurhai-list",
      },
      {
        label: "Тендерийн урилга",
        key: "/pages/procurement-list",
      },
      {
        label: "Тогтоол захирамж",
        key: "/pages/order-list",
      },
    ],
  },
  {
    label: "Хороод",
    key: "/pages/horoo-list",
  },
  {
    label: "Хэлтэс",
    children: [
      {
        label: "Үндсэн хэлтэс",
        key: "/pages/mainDepartments",
      },
      {
        label: "Харъяа хэлтэс",
        key: "/pages/relatedDepartments",
      },
    ],
  },
  {
    label: "Төрийн үйлчилгээ",
    key: "/pages/service-list",
  },
  {
    label: "Ил тод байдал",
    children: [
      {
        label: "Төсөв, санхүүгийн ил тод байдал",
        key: "/pages/trans_fin",
      },
      {
        label: "Үйл ажиллагааны ил тод байдал",
        key: "/pages/trans_work",
      },
      {
        label: "Хүний нөөцийн ил тод байдал",
        key: "/pages/trans_hr",
      },
      {
        label: "Худалдан авах ажиллагааны ил тод байдал",
        key: "/pages/trans_proc",
      },
      {
        label: "Өргөдөл гомдол шийдвэрлэлтийн тайлан",
        key: "/pages/trans_comp",
      },
      {
        label: "Дүүргийн архив",
        key: "/pages/trans_arch",
      },
      {
        label: "Хууль, эрх зүй",
        key: "/pages/trans_law",
      },
      {
        label: "Иргэдийг хүлээн авч уулзах цаг",
        key: "/pages/trans_time",
      },
      {
        label: "Сонгуулийн ил тод байдал",
        key: "/pages/trans_elec",
      },
      {
        label: "Хяналт шинжилгээ, үнэлгээ",
        key: "/pages/trans_rate",
      },
    ],
  },
  {
    label: "Шилэн данс",
    children: [
      {
        label: "Шилэн дансны тухай хууль",
        key: "https://www.legalinfo.mn/law/details/10497",
      },
      {
        label: "Төсвийн ерөнхийлөн захирагч",
        key: "https://www.shilendans.gov.mn/org/60",
      },
      {
        label: "Төсвийн шууд захирагч",
        key: "https://www.shilendans.gov.mn/org/4670",
      },
      {
        label: "Шилэн дансны мэдээлэл өгөх ажилтан",
        key: "pages/trans_ajiltan",
      },
      {
        label: "Тендер шалгаруулалтын үр дүн",
        key: "https://www.tender.gov.mn/mn/result/?year=2023&selGovernorId=154&selClientId=1453535505392&tenderCode=undefined&get=1",
      },
    ],
  },
];
export const statisticData = [
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M2 19V8H1V6h3V4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2h3v2h-1v11h1v2H1v-2h1zm11 0v-7h-2v7h2zm-5 0v-7H6v7h2zm10 0v-7h-2v7h2zM6 5v1h12V5H6z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "34",
    description: "Засаг захиргааны нэгж",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm13 14.764V7.176l-.065.028L9 4.236v12.588l.065-.028L15 19.764z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "2949",
    description: "Нутаг дэвсгэрийн хэмжээ",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M17.084 15.812a7 7 0 1 0-10.168 0A5.996 5.996 0 0 1 12 13a5.996 5.996 0 0 1 5.084 2.812zM12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "236385",
    description: "Суурин хүн амын тоо",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M20 20a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9zm-9-7v6h2v-6h-2z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "62784",
    description: "Өрхийн тоо",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M21 20h2v2H1v-2h2V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v17zM8 11v2h3v-2H8zm0-4v2h3V7H8zm0 8v2h3v-2H8zm5 0v2h3v-2h-3zm0-4v2h3v-2h-3zm0-4v2h3V7h-3z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "20",
    description: "Төрийн өмчийн сургууль",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M21 20h2v2H1v-2h2V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v17zM8 11v2h3v-2H8zm0-4v2h3V7H8zm0 8v2h3v-2H8zm5 0v2h3v-2h-3zm0-4v2h3v-2h-3zm0-4v2h3V7h-3z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "28",
    description: "Төрийн бус өмчийн сургууль",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M20 20a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9zm-8-3l3.359-3.359a2.25 2.25 0 1 0-3.182-3.182l-.177.177-.177-.177a2.25 2.25 0 1 0-3.182 3.182L12 17z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "37",
    description: "Төрийн өмчийн цэцэрлэг",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M20 20a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9zm-8-3l3.359-3.359a2.25 2.25 0 1 0-3.182-3.182l-.177.177-.177-.177a2.25 2.25 0 1 0-3.182 3.182L12 17z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "60",
    description: "Төрийн бус өмчийн цэцэрлэг",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M17 19h2v-8h-6v8h2v-6h2v6zM3 19V4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v5h2v10h1v2H2v-2h1zm4-8v2h2v-2H7zm0 4v2h2v-2H7zm0-8v2h2V7H7z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "35086",
    description: "Бүртгэлтэй ААН -н тоо",
  },
  {
    icon: (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="36"
          height="36"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M17 19h2v-8h-6v8h2v-6h2v6zM3 19V4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v5h2v10h1v2H2v-2h1zm4-8v2h2v-2H7zm0 4v2h2v-2H7zm0-8v2h2V7H7z"
            fill="rgba(12,120,190,1)"
          ></path>
        </svg>
      </div>
    ),
    value: "13347",
    description: "Үйл ажиллагаа явуулж буй ААН -н тоо",
  },
];
export const organizationsData = [
  {
    description: "Монгол Улсын Их Хурал",
    link: "http://parliament.mn/",
    image: "couch/uploads/image/taniltsuulga/links/parliament-mn.png",
  },
  {
    description: "МУ-н Ерөнхийлөгчийн тамгын газар",
    link: "http://www.president.mn/",
    image: "couch/uploads/image/taniltsuulga/links/president-mn.png",
  },
  {
    description: "Нийслэл хотын засаг даргын тамгын газар",
    link: "http://www.ulaanbaatar.mn/",
    image: "couch/uploads/image/taniltsuulga/links/ulaanbaatar-mn.png",
  },
  {
    description: "Авлигатай тэмцэх газар",
    link: "https://www.iaac.mn/",
    image: "couch/uploads/image/taniltsuulga/links/avilgatai-temtseh-gazar.png",
  },
  {
    description: "Засгийн газар",
    link: "https://zasag.mn/",
    image: "couch/uploads/image/taniltsuulga/links/zasag-mn.png",
  },
  {
    description: "Эрхзүйн мэдээллийн нэгдсэн систем",
    link: "https://www.legalinfo.mn/",
    image: "couch/uploads/image/taniltsuulga/links/legalinfo.png",
  },
  {
    description: "Нийслэлийн татварын газар",
    link: "http://mta.mn/",
    image: "couch/uploads/image/taniltsuulga/links/mta.png",
  },
  {
    description: "Цагдаагийн ерөнхий газар",
    link: "https://police.gov.mn/",
    image: "couch/uploads/image/taniltsuulga/links/tsagdaa.png",
  },
];
export const horooTabs = [
  { label: "Танилцуулга", key: "item-1", children: "Content 1" },
  { label: "Үйлчилгээ", key: "item-2", children: "Content 2" },
  { label: "Холбоо барих", key: "item-3", children: "Content 3" },
];
export const socialButtons = [
  {
    code: "weather",
    image: "\\assets\\images\\socialButtons\\ico_weather.svg",
    url: "http://www.tsag-agaar.gov.mn/",
  },
  {
    code: "fb",
    image: "\\assets\\images\\socialButtons\\ico_fb.svg",
    url: "https://www.facebook.com/shuurkhaishtab",
  },
  {
    code: "twitter",
    image: "\\assets\\images\\socialButtons\\ico_twitter.svg",
    url: "https://twitter.com/Bayangol_UB",
  },
  {
    code: "youtube",
    image: "\\assets\\images\\socialButtons\\ico_youtube.svg",
    url: "https://youtube.com/Bayangol_UB",
  },
];
